import React, { useState, useContext } from "react";
import app from "gatsby-plugin-firebase-v9.0";
import { navigate } from "gatsby";

import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { AuthContext } from "../context/authContext";

import CustomHead from "../components/common/Head";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    error: null,
  });

  const { setUser } = useContext(AuthContext);
  const auth = getAuth(app);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null });

    try {
      const result = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      setUser(result);
      navigate("/"); // TODO: change this to whatever page you were on before
    } catch (err) {
      setData({ ...data, error: err.message });
    }
  };
  return (
    <form>
      <h4>Login</h4>
      <fieldset>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          name="email"
          value={data.email}
          onChange={handleChange}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          value={data.password}
          onChange={handleChange}
        />
      </fieldset>

      {data.error && <p>{data.error}</p>}

      <button type="submit" onClick={handleSubmit}>
        Login
      </button>
    </form>
  );
};

export const Head = () => {
  return <CustomHead noIndex />;
};

export default Login;
